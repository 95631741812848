@import url("https://fonts.googleapis.com/css2?family=Anek+Latin:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Anek Latin", sans-serif;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  list-style: none;
  min-width: 0;
  min-height: 0;
}

.horizontal-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@layer components {
  .container {
    @apply flex w-[95%] max-w-[1300px] mx-auto  px-[5%] lg:px-[1%] flex-col items-stretch self-stretch flex-1;
  }

  .navLink {
    @apply text-base font-semibold lg:hover:underline relative;
  }

  .active-navLink {
    @apply text-base font-semibold underline relative text-jr_pink;
  }

  .primary-btn {
    /* @apply bg-jr_pink text-white w-full flex items-center  cursor-pointer text-sm p-5 sm:text-base flex-nowrap font-black h-12 justify-center tracking-wider transition-all duration-500 relative  md:hover:bg-v_purple600  */
  }

  .wishlist_box {
    @apply text-black bg-white shadow-[1px_1px_3px_1px_#b9b9b975] hover:shadow-[#0000001a_0px_16px_16px_0px,#b5b5be1a_0px_0px_10px_0px,#97979733_0px_0px_2px_0px,#97979759_0px_0px_1px_0px] cursor-pointer transition-all duration-300 ease-out overflow-hidden rounded-xl flex flex-col  relative p-3 justify-between;
  }

  .whatsapp {
    @apply fixed bottom-32 right-6 z-[100] flex h-12 w-12 items-center justify-center rounded-full bg-[#25d366] text-center text-lg shadow-xl md:bottom-20 md:text-2xl;
  }
}

/* select-arrow */
select {
  line-height: 1;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  appearance: none;
  background-position-y: 50%;
  background-position-x: 95%;
  background-size: 12px 12px;
}

.couples-website .react-tabs__tab-list {
  border-bottom: 1px solid transparent;
  margin: 0 0 0;
  padding: 0;
  width: 100%;
  min-width: 400px;
}

.couples-website .react-tabs__tab-panel--selected {
  display: flex;
}

.couples-website .react-tabs__tab {
  display: inline-block;
  text-align: center;
  border: none;
  border-bottom: 6px solid transparent;
  bottom: 0;
  position: inherit;
  list-style: none;
  padding: 6px 20px;
  cursor: pointer;
  color: #708090;
  transition: all 0.4s ease-out;
}

.couples-website .react-tabs__tab--selected {
  background: #fff;
  border-bottom: 6px solid #f9508c;
  color: #f9508c;
  border-radius: 0;
}

.couples-website .react-tabs__tab:hover {
  background-color: #f4e8ff;
  color: #f9508c;
}

/* couples-wishlist */
.couples-wishlist .slick-track {
  display: flex;
  gap: 16px;
}

@media (max-width: 1024px) {
  .couples-wishlist .slick-track {
    gap: 8px;
  }
}

.slick-prev,
.slick-next {
  border: 1px solid black !important;
  color: black !important;
  transition: all 0.2s ease-in;
  background: #fff !important;
  border-radius: 100%;
  z-index: 4;
  position: absolute;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled {
  display: none !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover,
.slick-next:active,
.slick-prev:active {
  background-color: #f9508c !important;
  color: #fff !important;
  border: 1px solid #f9508c !important;
}

.couples-wishlist .slick-prev {
  left: -10px;
}

.couples-wishlist .slick-next {
  right: -10px;
}
