.lds-dual-ring {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_page {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
}

.loader_page > div {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.loader_page > div:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid #f9508c;
  border-color: #f9508c transparent #f9508c transparent;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.user__paginate {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 40px;
}

.user__paginate > div {
  width: 200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #f9508c;
  border-radius: 30px;
}

.user__paginate > div > p {
  width: calc(100% - 100px);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.user__paginate > div > button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 50px;
  height: 50px;
}

.user__paginate > div > span {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 50px;
  height: 50px;
  opacity: 0.2;
}
